<template lang='pug'>
  #GPT.main-page.GPT-color.pb-0.position-relative.height_100vh.scroll-y.pb-5
    #page-inner.pb-0()
      loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
      #page-head.d-flex
        v-row.justify-space-between
          v-col(cols='2').d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4.pt-0
            h1.my-0.pt-0 {{$t('GPT_MENU.USAGE_ANALYSIS')}}
          v-col(cols='10').pr-0
              v-row.d-flex.justify-end
                v-col(cols='3')
                  v-select(v-model='selectBot' :title='getItemNameFromListById(botList,selectBot)' :items='botList' item-value='id' item-text='bot_name' prepend-inner-icon='fas fa-robot' height='30' flat dense solo)
                v-col(cols='3')
                  v-select(v-model='selectModel' :title='getItemNameFromListById(modelList,selectModel)' :items='modelList' item-value='id' item-text='name' height='30' flat dense solo)
                    template(v-slot:prepend-inner)
                      v-icon.pt-1(class='openailogo')
                v-col(cols='2')
                  v-select(v-model='selectDateMode' :title='getItemNameFromList(dateMode,selectDateMode)' :items='dateMode' item-value='key' item-text='name' prepend-inner-icon='mdi-calendar' height='30' flat dense solo @change='searchBotDate')
                v-col(v-if='selectDateMode == 5' cols='3')
                  v-menu(min-width='496px' ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scroll-y-transition" offset-y ) 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.pt-0(v-model='selectDateText' :title='selectDateText' v-bind="attrs" v-on="on" flat dense solo prepend-inner-icon='mdi-calendar-multiple' )
                        date-picker(v-model='selectDate' type="date" range  inline :showSecond='false' value-type='format' format='YYYY-MM-DD' @change="dateChange($event)")
                v-col(cols='1').d-flex.justify-end
                  refreshBtn(@EmitRefresh='onEmitRefresh')
      v-container.pt-8.upgpt-container(max-content)
            v-row.pb-0
              v-col.px-3(cols='6')
                v-card.rounded-card
                  div.d-flex.align-center.justify-md-space-between
                    v-card-text
                      .span {{$t('GPT_ANALYSIS.STORAGE_USAGE')}}
                        v-tooltip(right attach)
                            template(v-slot:activator='{ on, attrs }')
                                v-icon.pl-2.mb-1(size='20' v-bind="attrs" v-on="on") mdi-information-outline
                            span {{$t('GPT_ANALYSIS.STORAGE_USAGE_TIP')}}
                      .text-h4.mt-1.ellipsis.t-black  {{storage_size.uesd}}
                        span(style='font-size: 1rem;color:#000000;') G {{$t('GPT_ANALYSIS.USED')}}
                        span(style='font-size: 1rem;padding-left:5px;color:#5a5559;') ({{storage_size.percent}}%)
                        span(style='font-size: 1rem;padding-left:5px;color:#5a5559;') / {{storage_size.total}}G
                      v-progress-linear(:value="storage_size.percent" :color='storage_progress_color(storage_size.percent)')
              //- v-col.px-3(cols='4')
              //-   v-card.rounded-card
              //-     div.d-flex.align-center.justify-md-space-between
              //-       v-card-text
              //-         .span {{$t('GPT_IDP.DOCUMENT_ANALYSIS_STORAGE_USAGE')}}
              //-           v-tooltip(right attach)
              //-               template(v-slot:activator='{ on, attrs }')
              //-                   span ( {{$t('GPT_ANALYSIS.END_DATE')}} {{ intelligent_document_analysis.end_date}} )
              //-                   v-icon.pl-2.mb-1(size='20' v-bind="attrs" v-on="on") mdi-information-outline
              //-               span {{$t('GPT_ANALYSIS.INTELLIGENT_DOCUMENT_USAGE_TIP')}}
                        
              //-         .text-h4.mt-1.ellipsis.t-black {{image_search_store.uesd}}
              //-           span(style='font-size: 1rem;color:#000000;') {{$t('GPT_ANALYSIS.PAGE')}} {{$t('GPT_ANALYSIS.USED')}}
              //-           span(style='font-size: 1rem;padding-left:5px;color:#5a5559;') ({{image_search_store.percent}}%)
              //-           span(style='font-size: 1rem;padding-left:5px;color:#5a5559;') / {{intelligent_document_analysis.total}} {{$t('GPT_ANALYSIS.PAGE')}}
              //-         v-progress-linear(:value="image_search_store.percent" :color='storage_progress_color(image_search_store.percent)')
              v-col.px-3(cols='6')
                v-card.rounded-card
                  div.d-flex.align-center.justify-md-space-between
                    v-card-text
                      .span {{$t('GPT_ANALYSIS.IMAGE_STORAGE_USAGE')}}
                        v-tooltip(right attach)
                            template(v-slot:activator='{ on, attrs }')
                                v-icon.pl-2.mb-1(size='20' v-bind="attrs" v-on="on") mdi-information-outline
                            span {{$t('GPT_ANALYSIS.IMAGE_STORAGE_USAGE_TIP')}}
                      .text-h4.mt-1.ellipsis.t-black {{image_search_store.uesd}}
                        span(style='font-size: 1rem;color:#000000;') {{$t('GPT_ANALYSIS.PIECE')}} {{$t('GPT_ANALYSIS.USED')}}
                        span(style='font-size: 1rem;padding-left:5px;color:#5a5559;') ({{image_search_store.percent}}%)
                        span(style='font-size: 1rem;padding-left:5px;color:#5a5559;') / {{image_search_store.total}} {{$t('GPT_ANALYSIS.PIECE')}}
                      v-progress-linear(:value="image_search_store.percent" :color='storage_progress_color(image_search_store.percent)')
            v-row.pb-0
              v-col.px-3(cols='6')
                v-card.rounded-card
                  div.d-flex.align-center.justify-md-space-between
                    v-card-text
                      .span {{ selectDateMode == 5 ? $t('DASHBOARD.THIS_PERIOD'):getItemNameFromList(dateMode,selectDateMode)}}{{$t('GPT_ANALYSIS.ACU')}}
                        //- v-icon.pl-2.mb-1(size='20') mdi-information-outline
                      .text-h4.mt-1.ellipsis.t-black  {{thisMonthToken}}
                        span(style='font-size: 1rem;padding-left:10px;color:#18bedf;') US$ {{toCurrecy(thisMonthTokenUS)}}
                    //- v-card-text.d-flex.justify-end
                    //-   v-btn.ml-3.mb-1( right @click='' width='36' height='36' :ripple='false' color='#4ac6de' depressed dark fab)
                    //-       v-icon(size='20' ) mdi-calendar
              v-col.px-3(cols='6')
                v-card.rounded-card
                  div.d-flex.align-center.justify-md-space-between
                    v-card-text
                      .span {{ selectDateMode == 5 ? $t('GPT_ANALYSIS.PREVIOUS'):getItemNameFromList(lastDateMode,selectDateMode)}}{{$t('GPT_ANALYSIS.ACU')}}
                        //- v-icon.pl-2.mb-1(size='20') mdi-information-outline
                      .text-h4.mt-1.ellipsis.t-black  {{lastMonthToken}}
                        span(style='font-size: 1rem;padding-left:10px;;color:#18bedf;') US$ {{toCurrecy(lastMonthTokenUS)}}
                    //- v-card-text.d-flex.justify-end
                    //-   v-btn.ml-3.mb-1( right @click='' width='36' height='36' :ripple='false' color='#4ac6de' depressed dark fab)
                    //-       v-icon(size='20') mdi-calendar-check
            v-row()
              v-col(cols='12')
                v-card.rounded-card
                  v-row.align-center.pt-5.pl-3
                      v-col(cols='12').pt-0
                          .d-flex.align-center.justify-md-space-between
                            div.d-flex.align-center.justify-md-space-between
                              .cube-icon.small.bg-primary.mr-3
                                v-icon(size='19' color='white' v-if='showTokenChart') fa-solid fa-money-bill
                                v-icon(size='19' color='white' v-else) far fa-comment
                              h3.mb-0.d-flex.align-center
                                  v-col(cols='12' v-if='showTokenChart').pa-0.mt-4
                                    .text-caption {{$t('GPT_ANALYSIS.USED_TOKEN')}}
                                    .text-h5.mt-1.ellipsis  {{ toCurrecy(chartPrice) }}
                                      span(style='font-size: 0.8825rem;padding-left:10px;;color:#18bedf;') US$ {{toCurrecy(chartPriceUS)}}
                                  v-col(cols='12' v-else).pa-0.mt-4
                                    .text-caption {{$t('GPT_ANALYSIS.MSG_TOKEN')}}
                                    .text-h5.mt-1.ellipsis  {{ toCurrecy(perMsgPrice) }}
                                      span(style='font-size: 0.8825rem;padding-left:10px;;color:#18bedf;') US$ {{toCurrecy(perMsgPriceUS)}}
                            v-btn.ml-3.mb-1( @click='showTokenChart = !showTokenChart' width='36' height='36' :ripple='false' color='#4ac6de' depressed dark fab)
                              v-icon(size='16' v-if='showTokenChart') far fa-comment
                              v-icon(size='16' v-else) fa-solid fa-money-bill
                      v-col(cols='12' v-if='showTokenChart').pl-8.pr-10.pb-10
                          line-chart(v-if='!chartLoading' style='height:250px;position:relative;' :chart-data="chartData2" :options='chartOptions2')
                      v-col(cols='12' v-else).pl-8.pr-10
                          bar-chart(v-if='!chartLoading' style='height:250px;position:relative;' :chart-data="chartData" :options='chartOptions')
            v-row()
              v-col(cols='12')
                v-card.rounded-card
                  v-card-title.pt-6
                    v-row.justify-space-between
                      v-col(cols='3').pl-3
                        h3.mb-2.d-flex.align-center
                              .cube-icon.small.bg-purple.mr-3
                                  v-icon(size='19' color='white') icon-chart
                              span.mr-3 {{$t('GPT_MENU.USAGE_ANALYSIS')}}
                  v-card-text
                    v-data-table.pl-12.pr-16.mt-0(
                      :item-class="rowClass"
                      :headers="tableheaders",
                      :items="analysisData",
                      :items-per-page="itemPerPage",
                      :page.sync="tablePage",
                      page-count="pageCount",
                      hide-default-footer="hide-default-footer",
                      :loading='listLoading' 
                      loading-text='loading...'
                    )
                      template( v-slot:item.name="{ item }" )
                          .ellipsis(:title='item.name' height='36px')
                          div(class='d-flex justify-left' :title='item.name')
                              v-icon(style='margin-left:0px;' size='18') fas fa-robot
                              span(style='margin-left:20px;') {{item.name}}
                      template( v-slot:item.price="{ item }" )
                          .ellipsis(:title='item.price' height='36px')
                          div(class='d-flex justify-left' :title='item.price')
                              span $ {{toCurrecy(item.price)}}
                      template( v-slot:item.channel="{ item }" )
                          .ellipsis(:title='get_upchat_channel(item.channel)' height='36px')
                          div(class='d-flex justify-left' :title='get_upchat_channel(item.channel)')
                              span {{get_upchat_channel(item.channel)}}
                      template( v-slot:item.time="{ item }" )
                          .ellipsis(:title='item.time' height='36px')
                          div(class='d-flex justify-left' :title='getFormatTime(item.time)')
                              span {{getFormatTime(item.time)}}
                      template( v-slot:item.model="{ item }" )
                          .ellipsis(:title='getItemNameFromListById(modelList,item.model)' height='36px')
                          div(class='d-flex justify-left' :title='getItemNameFromListById(modelList,item.model)')
                              span {{getItemNameFromListById(modelList,item.model)}}
                            
                    v-pagination.mt-2(v-model='tablePage' :length='pageCount' circle :total-visible="10")
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addDialog' width='400' content-class='statusDialog' v-if='addDialog')
      add-dialog(@emitAddDialog='onEmitAddDialog')
    
</template>

<script>
import refreshBtn from "@/components/Common/refreshBtn";
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import addDialog from "@/components/GPT_Dialog/Knowledge/addKnowledge";
import i18n from '/common/plugins/vue-i18n.js';
import barChart from '../assets/script/barChart';
import lineChart from '../assets/script/lineChart';
import DatePicker from 'vue2-datepicker';
import UsageAnalysisService from "../services/UsageAnalysisService";
import AiBotsService from "../services/AiBotsService";
// import PreferenceService from "@/services/PreferenceService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    addDialog,
    barChart,
    lineChart,
    DatePicker,
    refreshBtn,
  },
  data() {
    return {
      // other
      loading:false,
      chartLoading:false,
      listLoading:false,
      isDelete:false,
      dateMenu:false,
      // Dialog
      addDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      price_data:{},
      selectModel:0,
      modelList:[
        {id:0,name:i18n.t('GENERAL.ALL')},
        {id:3.5,name:"GPT 3.5"},
        {id:4,name:"GPT 4"},
        {id:4.1,name:"GPT 4o"},
        {id:4.2,name:"GPT-4o mini"},
      ],
      selectDate:'',
      selectDateText:'',
      showTokenChart:true,
      lastMonthToken:0,
      lastMonthTokenUS:0,
      thisMonthToken:0,
      thisMonthTokenUS:0,
      chartPrice:0,
      chartPriceUS:0,
      perMsgPrice:0,
      perMsgPriceUS:0,
      analysisData:[],
      tablePage: 1,
      pageCount: 0,
      itemPerPage: 7,
      tableheaders: [
          {
              text: i18n.t('GPT_ANALYSIS.TABLE_HEADER_1'),
              align: 'left',
              sortable: false,
              value: 'bot_name',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_ANALYSIS.TABLE_HEADER_2'),
              align: 'left',
              sortable: false,
              value: 'channel',
              class: "white lighten-5",
          },
          {
              text: "Model",
              align: 'left',
              sortable: false,
              value: 'model',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_ANALYSIS.TABLE_HEADER_3'),
              align: 'left',
              sortable: false,
              value: 'prompt_tokens',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_ANALYSIS.TABLE_HEADER_4'),
              align: 'left',
              sortable: false,
              value: 'completion_tokens',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_ANALYSIS.TABLE_HEADER_5'),
              align: 'left',
              sortable: false,
              value: 'price',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_ANALYSIS.TABLE_HEADER_6'),
              align: 'left',
              sortable: false,
              value: 'created_on',
              class: "white lighten-5",
          },
      ],
      selectBot:0,
      botList:[
        {id:0,bot_name:i18n.t('GENERAL.ALL')}
      ],
      selectDateMode: 1,
      dateMode: [
          { key: 1, name: i18n.t('DASHBOARD.THIS_YEAR') },
          { key: 2, name: i18n.t('DASHBOARD.THIS_SEASON') },
          { key: 3, name: i18n.t('DASHBOARD.THIS_MONTH') },
          { key: 4, name: i18n.t('DASHBOARD.THIS_WEEK') },
          { key: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
      ],
      lastDateMode: [
          { key: 1, name: i18n.t('OP_DATE.LAST_YEAR') },
          { key: 2, name: i18n.t('OP_DATE.LAST_QUARTER') },
          { key: 3, name: i18n.t('OP_DATE.LAST_MONTH') },
          { key: 4, name: i18n.t('OP_DATE.LAST_WEEK') },
          { key: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
      ],
      chartData: {
        labels: [],
        datasets: [
            {
                label: i18n.t("GPT_ANALYSIS.MSG_TOKEN"),
                data: [20,50,700,1000,30,60,850,90,340,620,100,100],
                backgroundColor: '#78d6eb90',
                stack: 'Stack 0',
                yAxisID: 'A',
            },
            {
                label: i18n.t("GPT_ANALYSIS.MSG_Q"),
                data: [0.2,0.2,7,10,0.23,0.6,8.5,0.9,3.4,6.2,1,11],
                backgroundColor: '#FFFFFF00',
                borderColor: '#fbdf57',
                stack: 'Stack 0',
                yAxisID: 'B',
                type: 'line',
                lineTension:0,
            },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            id: 'A',
            type: 'linear',
            position: 'left',
          }, {
            id: 'B',
            type: 'linear',
            position: 'right',
            // ticks: {
            //   max: 1,
            //   min: 0
            // }
          }],
          xAxes: [{
            barPercentage: 0.4
        }]
        }
      },
      chartData2: {
        labels: [],
        datasets: [
            {
                label: 'Token',
                data: [20,50,700,1000,30,60,850,90,340,620,100,100],
                backgroundColor: '#78d6eb50',
                borderColor: '#78d6eb',
                stack: 'Stack 0',
                type: 'line',
                lineTension:0,
            },
        ]
      },
      chartOptions2: {
        responsive: true,
        maintainAspectRatio: false,
      },
      chartRespone:null,
      msg_count:0,
      upgpt_setting:null,
      storage_size:{
        uesd:0,
        total:0,
        percent:0
      },
      image_search_store:{
        uesd:0,
        total:0,
        percent:0
      },
      intelligent_document_analysis:{
        uesd:0,
        total:0,
        percent:0,
        end_date:0,
      },
      byte:1073741824
    };
  },
  async created() {
    this.loading = true;
    await this.get_upgpt_setting();
    await this.get_bot_list();
    await this.get_chart_data();
    await this.get_list_data();
    await this.tokenToCurrency();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    storage_progress_color(val){
        if(val<50) return 'primary';
        else if(val<=80) return 'orange';
        else return "red";
    },
    async get_upgpt_setting(){
        this.upgpt_setting = await this.upgpt_image_func_setting();
        this.storage_size.total = this.upgpt_setting.storage_size;
        this.image_search_store.total = this.upgpt_setting.image_search_store;
        this.intelligent_document_analysis.total = this.upgpt_setting.intelligent_document_pages;
        // end_date
        let format_date = new Date(this.upgpt_setting.intelligent_document_start_date);
        format_date.setFullYear(format_date.getFullYear() + 1);
        this.intelligent_document_analysis.end_date = format_date.toISOString().split('T')[0];
     
    },
    async get_bot_list(){
      await AiBotsService.select_list().then((response)=>{
        this.botList = this.botList.concat(response.data)
      })
    },
    async get_chart_data(){
      this.chartLoading = true;
      let strat = "";
      let end = "";
      let bot = "";
      let model = "";
      if(this.selectDateMode == 5 && this.selectDate != ""){
        let arr = this.selectDateText.split(" ~ ");
        strat = arr[0];
        end = arr[1];
      }
      if(this.selectBot != 0){
        bot = this.selectBot;
      }
      if(this.selectModel != 0){
        model = this.selectModel;
      }
      await UsageAnalysisService.chart(this.selectDateMode,bot,model,strat,end).then((response)=>{
        this.chartRespone = response.data;
        this.getChartLabel();
        this.chartData2.datasets[0].data = this.chartRespone.current.map(el => {return el.total})
        this.chartData.datasets[0].data = this.chartRespone.current.map(el => {return Math.floor(el.total/el.count) || 0})
        this.chartData.datasets[1].data = this.chartRespone.current.map(el => {return el.count});
        this.chartPrice = this.chartData2.datasets[0].data.reduce((bf,af)=>af=bf+af,0);
        this.perMsgPrice = this.chartData.datasets[0].data.reduce((bf,af)=>af=bf+af,0)/this.chartData.datasets[1].data.reduce((bf,af)=>af=bf+af,0);
        this.perMsgPrice = parseFloat(this.perMsgPrice.toFixed(3));
        this.msg_count = this.chartRespone.current.reduce((a,b) => a+b.count , 0);
        this.thisMonthToken = this.chartPrice;
        this.lastMonthToken = this.chartRespone.last.map(el => {return el.total}).reduce((bf,af)=>af=bf+af,0);
        this.storage_size.uesd = (response.data.storage.storageSize/this.byte).toFixed(2);
        this.storage_size.percent = (this.storage_size.uesd/this.storage_size.total*100).toFixed(2);
        this.image_search_store.uesd = response.data.image_storage.documentCount;
        this.image_search_store.percent = this.image_search_store.total == 0 ? 0:(this.image_search_store.uesd/this.image_search_store.total*100).toFixed(2)
      })
      this.chartLoading = false;
    },
    async get_list_data(){
      this.listLoading = true;
      let strat = "";
      let end = "";
      let bot = "";
      let model = "";
      if(this.selectDateMode == 5 && this.selectDate != ""){
        let arr = this.selectDateText.split(" ~ ");
        strat = arr[0];
        end = arr[1];
      }
      if(this.selectBot != 0){
        bot = this.selectBot;
      }
      if(this.selectModel != 0){
        model = this.selectModel;
      }
      await UsageAnalysisService.list(this.selectDateMode,bot,model,strat,end,this.tablePage,this.itemPerPage).then((response)=>{
        this.price_data = response.data.price;
        this.analysisData = response.data.list;
        this.analysisData.forEach(el=>{el.created_on = this.getFormatTime(el.created_on)})
        this.pageCount = response.data.total_page;
      })
      this.listLoading = false;
    },
    dateChange(val){
      this.selectDateText = val.join(" ~ ");
    },
    searchBotDate(){
      if(this.selectDateMode == 5){
        setTimeout(()=>{
          this.dateMenu = true;
        },100)
      }
    },
    tokenToCurrency(){
      // let total_cp = this.chartRespone.current.reduce((a,b)=>a+b.cp,0)/1000;
      // let total_pt = this.chartRespone.current.reduce((a,b)=>a+b.pt,0)/1000;
      // let total_cp_4 = this.chartRespone.current.reduce((a,b)=>a+b.cp_4,0)/1000;
      // let total_pt_4 = this.chartRespone.current.reduce((a,b)=>a+b.pt_4,0)/1000;
      // let last_total_cp = this.chartRespone.last.reduce((a,b)=>a+b.cp,0)/1000;
      // let last_total_pt = this.chartRespone.last.reduce((a,b)=>a+b.pt,0)/1000;
      // let last_total_cp_4 = this.chartRespone.last.reduce((a,b)=>a+b.cp_4,0)/1000;
      // let last_total_pt_4 = this.chartRespone.last.reduce((a,b)=>a+b.pt_4,0)/1000;
      let total_cost = this.chartRespone.current.reduce((a,b)=>a+b.total_cost,0);
      let last_total_cost = this.chartRespone.last.reduce((a,b)=>a+b.total_cost,0);

      this.thisMonthTokenUS = Math.ceil(total_cost*1000)/1000;
      this.lastMonthTokenUS = Math.ceil(last_total_cost*1000)/1000;
      this.chartPriceUS = this.thisMonthTokenUS;
      this.perMsgPriceUS = Math.ceil((this.chartPriceUS/this.msg_count)*1000)/1000;
      this.lastMonthToken = this.toCurrecy(this.lastMonthToken);
      this.lastMonthTokenUS = this.toCurrecy(this.lastMonthTokenUS);
      this.thisMonthToken = this.toCurrecy(this.thisMonthToken);
      this.thisMonthTokenUS = this.toCurrecy(this.thisMonthTokenUS);
    },
    async getChartLabel(){
        let days = [];
        switch(this.selectDateMode){
            case 1:
                this.chartData.labels = ['Jan', 'Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                break;
            case 2:
                this.chartData.labels = this.getQuater();
                break;
            case 3:{
                days = this.chartRespone.current.map(el => {return el.dateday})
                this.chartData.labels = days;
                break;}
            case 4:
                this.chartData.labels = ['Sunday','Monday', 'Tuesday','Wednesday', 'Thursday','Friday','Saturday'];
                for(let i in this.chartData.labels){
                  this.chartData.labels[i] = this.chartData.labels[i]+" "+this.chartRespone.current[i].dateday.slice(5);
                }
                break;
            case 5:
                days = this.chartRespone.current.map(el => {return el.dateday})
                this.chartData.labels = days;
                break; 
        }
        this.chartData2.labels = this.chartData.labels;
    },
    getQuater(){
          let thisMonth = new Date().getMonth();
          if(thisMonth == 0 || thisMonth == 1 || thisMonth == 2){
              return ['Jan', 'Feb','Mar'];
          }
          else if(thisMonth == 3 || thisMonth == 4 || thisMonth == 5){
              return ['Apr','May','Jun'];
          }
          else if(thisMonth == 6 || thisMonth == 7 || thisMonth == 8){
              return ['Jul','Aug','Sep'];
          }
          else if(thisMonth == 9 || thisMonth == 10 || thisMonth == 11){
              return ['Oct','Nov','Dec'];
          }
      },
    rowClass() {
        const rowClass = 'dataTableColor'
        return rowClass;
    }, 
    toCurrecy(nums){
        let parts = nums.toString().split('.');
        parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        return parts.join('.');
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }                
    },
    get_upchat_channel(data){
        let channel_str = data.toLowerCase()
        let channel_name = ''
        switch(true){
            case channel_str.includes('facebook'):
                return "Facebook"
            case channel_str.includes('line'):
                return "Line"
            case channel_str.includes('webchat'):
                return "Webchat"
            case channel_str.includes('whatsapp'):
                return "WhatsApp"
            case channel_str.includes('sms'):
                return "SMS"
            case channel_str.includes('mail'):
                return "Mail"
            case channel_str.includes('teams'):
                return "Teams"
            case channel_str.includes('upside'):
                return "UpSide"
            default:
                if(data.split('::').length>1){
                    channel_name = data.split('::')[1];
                }else{
                    channel_name = 'Web';
                }
                return channel_name
        }
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    showAddDialog() {
      this.addDialog = true;
    },
    onEmitAddDialog(){
      this.addDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog() {
      this.messageDialog = false;
    },
    async onEmitRefresh(){
        this.loading = true;
        await this.get_bot_list();
        await this.get_chart_data();
        await this.get_list_data();
        await this.tokenToCurrency();
        this.loading = false;
    },
  },
  watch:{
    "messageDialog":function(){
      if(!this.messageDialog){
        this.isDelete = false;
      }
    },
    "selectDate":async function(){
      await this.get_chart_data();
      if(this.tablePage != 1){
        this.tablePage = 1;
      }else{
        await this.get_list_data();
      }
      await this.tokenToCurrency();
    },
    "selectBot":async function(){
      await this.get_chart_data();
      if(this.tablePage != 1){
        this.tablePage = 1;
      }else{
        await this.get_list_data();
      }
      await this.tokenToCurrency();
    },
    "selectModel":async function(){
      await this.get_chart_data();
      if(this.tablePage != 1){
        this.tablePage = 1;
      }else{
        await this.get_list_data();
      }
      await this.tokenToCurrency();
    },
    "selectDateMode":async function(){
      if(this.selectDateMode == 5)
      {
        if(this.selectDate != ""){
          await this.get_chart_data();
          if(this.tablePage != 1){
            this.tablePage = 1;
          }else{
            await this.get_list_data();
          }
        }
      }else{
        await this.get_chart_data();
        if(this.tablePage != 1){
          this.tablePage = 1;
        }else{
          await this.get_list_data();
        }
      }
      await this.tokenToCurrency();
    },
    "tablePage":async function(){
      await this.get_list_data();
    },
  }
};
</script>

<style lang="css"> 
  .circle_fill_information{
    height: 20px;
    width: 20px;
    margin: 0 0 1% 2%;
    content: url('https://api.iconify.design/mdi/information-outline.svg?color=%23888bff');
  }

  .openailogo{
    height: 22px;
    width: 22px;
    content: url('https://api.iconify.design/logos/openai-icon.svg');
  }
</style>